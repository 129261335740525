.wraparound {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--s0);
  padding: var(--s2) 0;

  & button {
    display: block;
    margin: 0 auto;
  }
}

.pictures {
  --product-image-height: var(--s7);
  width: 100%;
  height: 34vmin;
  max-height: 22vmax;
  background: center / auto 100% repeat-x;
}
