.tripleProduct {
  margin-top: var(--s2);
  margin-bottom: var(--s3);
  align-items: center;
}

.images {
  /* Wrap shirts to the next row... */
  display: flex;
  flex-wrap: wrap;
  /* ... but hide the shirts that wrap. */
  max-height: min(460px, 88vw);
  gap: 15px;
  overflow: hidden;
  /* Center whatever shirts we can see. */
  justify-content: center;
  align-items: center;

  & > * {
    padding: 10px 0;
    /* Don't get bigger than the desktop design, or outgrow the screen. */
    max-width: min(440px, 90%);
    z-index: -1; /* don't obscure the border */

    /* Fine-tune the left shirt to look centered when rotated. */
    &:nth-child(1) {
      transform: rotate(-5.74deg);
    }

    &:nth-child(2) {
      transform: rotate(180deg) translateY(2%);
    }

    &:nth-child(3) {
      transform: rotate(6.32deg);
      z-index: -2; /* don't obscure the middle shirt */
    }
  }
}
