.blurb {
  padding: var(--s1) 0;

  & p {
    margin: auto;
    text-align: center;
    max-width: calc(var(--measure) * 3 / 4);
    font-size: clamp(var(--f1), 5vw, var(--f5));
    font-weight: 700;
    line-height: inherit;
    white-space: pre-line;
  }
}
