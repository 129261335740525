.instagrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(150px, 20%), 1fr));
  gap: var(--s_2);

  & > * {
    /*
     * Padding hack: https://css-tricks.com/aspect-ratio-boxes/
     * Replace with `aspect-ratio` when we drop iOS 14 support.
     */
    position: relative;
    width: 0;
    height: 0;
    padding: 50%;
  }
}

.marquee {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: var(--s0);
  justify-content: space-evenly;
  height: var(--s2);

  &,
  & > li {
    margin: 0;
    padding: 0;
  }
}
