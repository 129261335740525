.campaignHero {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.foregroundLight {
    --color-text-hero: var(--color-text-inverse);
  }

  &.foregroundDark {
    --color-text-hero: var(--color-text-default);
  }
}

.heroImage {
  object-fit: cover;
  object-position: 60% center;
}

.heroText {
  color: var(--color-text-hero);
  justify-content: flex-end;
  flex-grow: 1;
  padding: var(--s1) 8vw;

  & h1 {
    max-width: 20ch;
    text-shadow: 5px 5px 15px var(--color-shadow);
  }

  & a:hover {
    color: var(--color-text-default);
  }

  & a:hover:focus {
    outline-color: var(--color-bg-accent);
  }
}

.fundraising {
  max-width: none;
  padding: var(--s_1);
  font-size: clamp(var(--f_1), 2vw, var(--f2));
  background: var(--color-bg-accent);
  text-align: center;
  font-weight: bold;
}
