.quote {
  margin: auto;
  padding: var(--s3) 0;
  text-align: center;

  & > * + * {
    margin-top: var(--s0);
  }

  & blockquote {
    &::before {
      content: '“';
    }

    &::after {
      content: '”';
    }

    font-size: var(--f3);
    font-weight: 700;
    max-width: calc(var(--measure) * 4 / 5);
  }

  & figcaption {
    text-align: left;
  }
}

.avatar {
  display: block;
  height: 65px;
  width: 65px;
  border-radius: 50%;
}

.name {
  font-size: var(--f1);
  font-weight: 700;
  white-space: pre-line;
}

.description {
  font-size: var(--f0);
  font-weight: 600;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  max-width: 48ch;
  white-space: pre-line;
}
